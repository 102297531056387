/* Route container */
.login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
}

/* Form styling */
.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80vh;
  width: 30vw;
}

.login-form .login-title {
  font-size: 30px;
  color: #403866;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;

  width: 100%;
  display: block;
}

/* Input fields */
.login-form input {
  outline: none;
  border: none;
  overflow: visible;
  margin: 0;
  touch-action: manipulation;
}

.login-form .input-field {
  display: flex;
  flex-direction: row;
  background: #e6e6e6;
  margin-bottom: 16px;
}

.input-field .email-field {
  color: #403866;
  line-height: 1.2;
  font-size: 18px;

  display: block;
  width: 100%;
  background: transparent;
  height: 62px;
  padding: 0 20px 0 38px;
}

.input-field .password-field {
  color: #403866;
  line-height: 1.2;
  font-size: 18px;

  display: block;
  width: 100%;
  background: transparent;
  height: 62px;
  padding: 0 20px 0 38px;
}

/* Error Messages */
.error-message {
  position: relative;
  top: 0.75em;
  right: -1em;
  width: 0px;
  height: 0px;
}

.error-message p {
  white-space: nowrap;
  color: #e84118;
}

/* Show password */
.show-password-field {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 1em;
}

.show-password-field input[type='checkbox'] {
  width: 1em;
  height: 1em;
  font-size: 2em;
  margin-right: 0.5em;  
}

.show-password-field p {
  margin-top: 0.5em;
  margin-bottom: 0;
  font-size: 1em;
}

.submit-button {
  font-size: 1em;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  cursor: pointer;
  
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 62px;
  background-color: #827ffe;
  border-radius: 3px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  border: none;
}

.submit-button:hover {
  background-color: #403866;
}
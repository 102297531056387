html,
body,
#app,
.App {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.menu-navbar {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  background: black;
}

.menu-item-container {
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
}

.menu-item-container p {
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid white;
  text-align: center;
}

.menu-item-container:focus {
    background-color: white;
    color: black;
  }

.menu-item-container:hover {
  color: goldenrod;
}

.menu-item-content {
  display: inline-flex;
  flex-direction: column;
  margin: auto 1rem;
  border: 1px solid black;
  padding: 1rem;
}

.menu-flex-row {
  display: inline-flex;
  flex-direction: row;
}

.menu-flex-row-content {
  display: inline-flex;
  flex-direction: column;
}


/*** Table Styles **/

.table-fill {
  display: table;
  background: white;
  border-radius:10px;
  border-collapse: collapse;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  font-size: 1rem;
}

/*
  TODO: Themes

  Default: #6c7ae0
  BRH Green: #80a940
  BRH Blue: #5481ab
*/
th {
  color:#D5DDE5;
  background:#5481ab;
  font-size: calc(0.5vw + 0.75rem);
  border-bottom:4px solid #9ea7af;
  border-right: 1px solid #343a45;
  font-weight: 100;
  padding: 0.5vw;
  text-align:left;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  vertical-align:middle;
}

th:first-child {
  border-top-left-radius:3px;
}

th:last-child {
  border-top-right-radius:3px;
  border-right:none;
}

tr {
  border-top: 1px solid #C1C3D1;
  border-bottom: 1px solid #C1C3D1;
  color:#666B85;
  font-weight:normal;
  text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
}

tr:hover td {
  background:#ececff;
  color:#666666;
  /*border-top: 1px solid #22262e;*/
}

tr:first-child {
  border-top:none;
}

tr:last-child {
  border-bottom:none;
}

tr:nth-child(odd) td {
  background:#EBEBEB;
}

tr:nth-child(odd):hover td {
  background:#ececff;
}

tr:last-child td:first-child {
  border-bottom-left-radius:3px;
}

tr:last-child td:last-child {
  border-bottom-right-radius:3px;
}

td {
  background:#FFFFFF;
  padding:0.5rem;
  text-align:left;
  vertical-align:middle;
  font-weight:300;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #C1C3D1;
  font-size: calc(0.5vw + 0.75rem);
}

td:last-child {
  border-right: 0px;
}

th.text-left {
  text-align: left;
}

th.text-center {
  text-align: center;
}

th.text-right {
  text-align: right;
}

td.text-left {
  text-align: left;
}

td.text-center {
  text-align: center;
}

td.text-right {
  text-align: right;
}
